import * as React from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Dialog,
  FormGroup,
  FormControlLabel,
  Link,
  MuiAlert,
  Typography,
  TextField,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import FacebookIcon from "@mui/icons-material/Facebook";
import { setCookie, getCookie } from "cookies-next";
import { useRouter } from "next/router";
import Axios from "axios";
import JwtDecode from "jwt-decode";
import styles from "../../styles/Auth.module.css";
import useEffect from "../../hooks/useEffectOne";

export default function ModalLogin(props) {
  const router = useRouter();

  const [email, onChnageEamil] = React.useState("");
  const [password, onChnagePassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {});

  const handleLogin = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams();
      params.append("emailOrPhoneNumber", email);
      params.append("password", password);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const response = await Axios.post(
        "https://booclii-api.tiravat.com/api/v0.0.1/customers/authenticate",
        params,
        config
      );
      if (response.status === 200) {
        setCookie("accessToken", response.data.accessToken);
        setCookie("refreshToken", response.data.refreshToken);
        const decode = JwtDecode(response.data.accessToken);
        console.warn("decode", decode);
        setCookie("name", `${decode.customerName}`);
        setTimeout(() => {
          setLoading(false);
          props.onClose();
          window.location.reload();
        }, 2000);
      }

      console.warn("response", response);
    } catch (e) {
      console.log("err login", e);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      {props && (
        <Dialog
          open={props.isVisible}
          onClose={props.onClose}
          maxWidth={"sm"}
          fullWidth
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <div />
              <Typography
                variant="h6"
                style={{ marginLeft: 10, fontWeight: "bold" }}
              >
                Login / Register
              </Typography>
              <CloseIcon onClick={props.onClose} />
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h3"
                style={{ marginTop: 20, color: "#3E5A50" }}
              >
                booclii
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 20,
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TextField
                id="outlined-start-adornment"
                fullWidth
                placeholder={"E-mail"}
                onChange={(e) => onChnageEamil(e.target.value)}
                size="medium"
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="outlined-start-adornment2"
                fullWidth
                type="password"
                placeholder={"Password"}
                onChange={(e) => onChnagePassword(e.target.value)}
                size="medium"
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: 15 }}
              />

              <div className={styles.divForgotPassword}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={"Remember me"}
                  />
                </FormGroup>
                <div
                  style={{
                    marginTop: "7px",
                  }}
                >
                  <Link href="/forgotpassword" underline="none">
                    <span style={{ color: "black" }}>{"Forgot password!"}</span>
                  </Link>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <LoadingButton
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#3E5A50",
                  }}
                  onClick={() => handleLogin()}
                  loading={loading}
                >
                  {"Login"}
                </LoadingButton>
              </div>
              <div className={styles.authAccount}>
                <div
                  style={{
                    color: "#00000080",
                  }}
                >
                  {"Don’t have account ?"}
                </div>
                <Link href="/register">
                  <a
                    style={{
                      color: "#3E5A50",
                      marginLeft: "10px",
                    }}
                  >
                    {"Sign Up"}
                  </a>
                </Link>
              </div>
            </div>
            <Divider>or</Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 20,
                width: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "85%" }}>
                <Link
                  underline="none"
                  href="#"
                  style={{
                    border: `1px solid #3E5A50`,
                    padding: 10,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/124/124010.png"
                    alt="facebook"
                    width={"5%"}
                    height={20}
                    style={{
                      marginLeft: "16px",
                      marginTop: 2,
                      borderRadius: 5,
                    }}
                  />
                  <span style={{ color: "#3E5A50" }}>
                    Continue with Facebook
                  </span>
                  <div />
                </Link>
                <Link
                  underline="none"
                  href="#"
                  style={{
                    border: `1px solid #3E5A50`,
                    padding: 10,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 15,
                  }}
                >
                  <img
                    src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-webinar-optimizing-for-success-google-business-webinar-13.png"
                    alt="google"
                    width={"5%"}
                    height={20}
                    style={{
                      marginLeft: "16px",
                      marginTop: 2,
                      borderRadius: 5,
                    }}
                  />
                  <span style={{ color: "#3E5A50" }}>Continue with Google</span>
                  <div />
                </Link>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
}
